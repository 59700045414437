import React, { useCallback, useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, ViewStyle, TextStyle } from 'react-native';

import { palette, spacing } from '../theme';

const styles = StyleSheet.create({
  control: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.small,
  },
  radioCircle: {
    alignItems: 'center',
    borderColor: palette.blue,
    borderRadius: 30,
    borderWidth: 2,
    height: 30,
    justifyContent: 'center',
    width: 30,
  },
  radioText: {
    color: palette.darkGrey,
    marginRight: spacing.small,
  },
  selectedRb: {
    backgroundColor: palette.blue,
    borderRadius: 30,
    height: 15,
    width: 15,
  },
});

type RadioEntry = {
  key: string;
  label: string;
};

export interface RadioButtonProps {
  entries: RadioEntry[];
  selectedKey?: string | undefined;
  onChange?: (entry: RadioEntry) => void;
  style?: ViewStyle;
  containerStyle?: ViewStyle;
  labelStyle?: TextStyle;
}

/**
 * Describe your component here
 */
export function RadioButton({
  entries,
  selectedKey,
  onChange,
  containerStyle,
  style,
  labelStyle,
}: RadioButtonProps) {
  const [currentValue, setCurrentValue] = useState<RadioButtonProps['selectedKey']>(selectedKey);

  const onSelect = useCallback(
    (entry: RadioEntry) => {
      setCurrentValue(entry.key);
      if (onChange) {
        onChange(entry);
      }
    },
    [onChange],
  );

  useEffect(() => setCurrentValue(selectedKey), [selectedKey]);

  return (
    <View style={containerStyle}>
      {entries.map((entry) => {
        return (
          <View key={entry.key} style={[styles.control, style]}>
            <Text style={[styles.radioText, labelStyle]}>{entry.label}</Text>
            <TouchableOpacity style={styles.radioCircle} onPress={() => onSelect(entry)}>
              {currentValue === entry.key && <View style={styles.selectedRb} />}
            </TouchableOpacity>
          </View>
        );
      })}
    </View>
  );
}
