import { useMemo, useCallback, useState, useRef, MutableRefObject } from 'react';
import { IClientOptions, MqttClient, QoS, connect } from 'mqtt';
import { isObject } from 'ramda-adjunct';

export type MQTTConnectionStatus = 'disconnected' | 'connected' | 'connecting' | 'reconnecting';
export interface MQTTSubscription {
  topic: string;
  qos: QoS;
}
export interface MQTTMessagePayload {
  topic: string;
  data: any;
}
export interface MQTTPublishPayload extends MQTTMessagePayload {
  qos: QoS;
}

export const useMQTT = () => {
  const client = useRef<MqttClient>(null);
  const subscriptions = useRef<string[]>([]);
  const [connectionStatus, setConnectionStatus] = useState<MQTTConnectionStatus>('disconnected');
  const [messagePayload, setMessagePayload] = useState<MQTTMessagePayload | null>(null);

  const isSubscribed = useCallback((topic: string) => subscriptions.current.includes(topic), []);

  const mqttConnect = useCallback((brokerUrl?: string | any, mqttOptions?: IClientOptions) => {
    setConnectionStatus('connecting');
    console.log('MQTT :: Connecting', brokerUrl, mqttOptions);
    (client as MutableRefObject<MqttClient>).current = connect(brokerUrl, mqttOptions);
    if (client.current) {
      client.current.on('connect', () => {
        console.log('MQTT :: Connected', client.current);
        setConnectionStatus('connected');
      });
      client.current.on('error', (error: any) => {
        console.log(error);
        setConnectionStatus('disconnected');
        if (client.current) {
          client.current.end();
        }
      });
      client.current.on('reconnect', () => {
        console.log('MQTT :: Reconnecting');
        setConnectionStatus('reconnecting');
      });
      client.current.on('message', (topic: string, data: any) => {
        const payload = { topic, data };
        console.log('MQTT :: Message received', topic, data);
        setMessagePayload(payload);
      });
    }
  }, []);

  const mqttDisconnect = useCallback(() => {
    if (client.current) {
      client.current.end(true, undefined, () => {
        setConnectionStatus('disconnected');
      });
    }
  }, []);

  const mqttPublish = useCallback(
    (payload: MQTTPublishPayload) => {
      if (client.current) {
        const { topic, qos, data } = payload;
        const dataString = isObject(data) ? JSON.stringify(data) : data.toString();
        client.current.publish(topic, dataString, { qos }, (error: any) => {
          if (error) {
            console.log('Publish error: ', error);
          }
        });
      }
    },
    [client],
  );

  const mqttSubscribe = useCallback((subscription: MQTTSubscription) => {
    if (client.current) {
      const { topic, qos } = subscription;
      client.current.subscribe(topic, { qos }, (error: any) => {
        if (error) {
          console.log('Subscribe to topics error', error);
          return;
        }
        subscriptions.current.push(topic);
      });
    }
  }, []);

  const mqttUnSubscribe = useCallback((subscription: MQTTSubscription) => {
    if (client.current) {
      const { topic } = subscription;
      client.current.unsubscribe(topic, (error: any) => {
        if (error) {
          console.log('Unsubscribe error', error);
          return;
        }
        subscriptions.current.splice(subscriptions.current.indexOf(topic), 1);
      });
    }
  }, []);

  return useMemo(
    () => ({
      isSubscribed,
      connectionStatus,
      messagePayload,
      mqttConnect,
      mqttDisconnect,
      mqttPublish,
      mqttSubscribe,
      mqttUnSubscribe,
    }),
    [
      isSubscribed,
      connectionStatus,
      messagePayload,
      mqttConnect,
      mqttDisconnect,
      mqttPublish,
      mqttSubscribe,
      mqttUnSubscribe,
    ],
  );
};
