import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ImageBackground,
  ImageStyle,
  StyleSheet,
  View,
} from 'react-native';
import { Button, Overlay } from 'react-native-elements';
import { allPass, hasIn } from 'ramda';
import { isNotEmpty, isObject } from 'ramda-adjunct';
import Svg, { Path, Text as TextSvg } from 'react-native-svg';

import { palette, spacing } from '../theme';
import {
  MQTTMessagePayload,
  MQTTSubscription,
  useAlcoBroker,
} from '../services/mqtt';

const parsePayload = (payload: MQTTMessagePayload) => {
  if (payload && hasIn('data', payload)) {
    const dataString = payload.data.toString();
    try {
      return JSON.parse(dataString);
    } catch {
      return undefined;
    }
  }
};

const isOfferMessage = allPass([isObject, isNotEmpty, hasIn('offer')]);

export interface OfferWidgetProps {
  missionId: string;
}

const styles = StyleSheet.create({
  backdrop: { backgroundColor: '#00000090' },
  buyButton: { bottom: 0, margin: spacing.small },
  overlay: { backgroundColor: palette.transparent },
  priceBox: {
    paddingTop: spacing.smaller,
  },
});

export function OfferWidget({ missionId }: OfferWidgetProps) {
  const {
    connectionStatus,
    isSubscribed,
    mqttSubscribe,
    mqttUnSubscribe,
    messagePayload,
  } = useAlcoBroker();

  const [visible, setVisible] = useState(false);
  const [offer, setOffer] = useState<
    { price: string; image: string } | null
  >(null);

  const imagePreview = useMemo<ImageStyle>(
    () =>
      ({
        width: 600,
        height: 600,
        aspectRatio: 1,
        borderRadius: spacing.tiny,
        justifyContent: 'space-between',
      } as ImageStyle),
    [],
  );

  const subscription = useMemo<MQTTSubscription>(
    () => ({ topic: `mission/${missionId}`, qos: 1 }),
    [missionId],
  );

  const dismissOffer = useCallback(() => {
    setVisible(false);
    setOffer(null);
  }, []);

  useEffect(() => {
    if (connectionStatus === 'connected' && !isSubscribed(subscription.topic)) {
      mqttSubscribe(subscription);
    }
    return () => {
      mqttUnSubscribe(subscription);
    };
  }, [
    connectionStatus,
    isSubscribed,
    mqttSubscribe,
    mqttUnSubscribe,
    subscription,
  ]);

  useEffect(() => {
    if (messagePayload) {
      const message = parsePayload(messagePayload);
      if (isOfferMessage(message)) {
        setOffer(message.offer);
        setVisible(true);
      }
    }
  }, [messagePayload, subscription.topic]);

  return (
    <Overlay
      isVisible={visible && !!offer}
      onBackdropPress={dismissOffer}
      overlayStyle={styles.overlay}
      backdropStyle={styles.backdrop}
    >
      <>
        {offer && offer.image && (
          <View>
            <ImageBackground
              source={{ uri: `data:image/gif;base64,${offer.image}` }}
              style={imagePreview}
            >
              <View style={styles.priceBox}>
                <Svg width="120" height="30">
                  <Path
                    d="M0,0 h100 q3,0 3,3 l-5,12 l5,12 q0,3 -3,3 h-100 z"
                    fill={palette.blue}
                  />
                  <TextSvg
                    x="50"
                    y="21"
                    textAnchor="middle"
                    fontWeight="bold"
                    fontSize="18"
                    fill={palette.white}
                  >
                    {offer.price}
                  </TextSvg>
                </Svg>
              </View>
              <Button
                style={styles.buyButton}
                title="Get Offer"
                onPress={dismissOffer}
              />
            </ImageBackground>
          </View>
        )}
      </>
    </Overlay>
  );
}
