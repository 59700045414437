export const spacing = {
  /**
   * elements contextually close to each other
   */
  tiny: 4,
  /**
   * for groups of closely related items or perhaps borders
   */
  smaller: 8,
  /**
   *
   */
  small: 12,
  /**
   *
   */
  medium: 16,
  /**
   * between groups of content that aren't related?
   */
  large: 24,
  /**
   *
   */
  xLarge: 32,
  /**
   *
   */
  huge: 48,
  /**
   *
   */
  massive: 56,
  /**
   *
   */
  enormous: 70,
  /**
   *
   */
  gigantic: 80,
  /**
   *
   */
  immense: 120,
  /**
   *
   */
  vast: 140,
  /**
   *
   */
  tremendous: 200,
};
