export const palette = {
  transparent: 'rgba(0, 0, 0, 0)',
  black: '#000000',
  white: '#ffffff',
  offWhite: '#F2F2F2',
  green: '#07DA98',
  glowingGreen: '#059165',
  blue: '#6D79F4',
  grey: '#999999',
  lightGrey: '#939AA4',
  darkGrey: '#464646',
  red: '#FF635B',
};
