import React from 'react';
import { ThemeProvider } from 'react-native-elements';
import { StyleSheet, View } from 'react-native';

import { palette, spacing, theme } from './theme';
import { MissionScreen } from './screens';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    margin: spacing.small,
    maxWidth: 1920,
    minWidth: 640,
    backgroundColor: palette.white,
    borderRadius: spacing.tiny,
    paddingBottom: spacing.small,
  },
});

function App() {
  return (
    <>
      <style type="text/css">{`
      @font-face {
        font-family: 'MaterialIcons';
        src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
      }
    `}</style>

      <ThemeProvider theme={theme}>
        <View style={styles.root}>
          <MissionScreen />
        </View>
      </ThemeProvider>
    </>
  );
}

export default App;
