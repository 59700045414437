import { isObject, isNotEmpty } from 'ramda-adjunct';
import { useEffect, useMemo, useState } from 'react';
import { allPass, hasIn } from 'ramda';

import { MQTTMessagePayload, MQTTSubscription } from './use-mqtt';
import { useAlcoBroker } from './use-alco-broker';

export interface SensorsData {
  humi?: number;
  temp?: number;
  tvoc?: number;
  co2?: number;
  alMin?: number;
  alMax?: number;
  alAvg?: number;
}

const parseMessage = (messagePayload: MQTTMessagePayload) => {
  if (messagePayload && hasIn('data', messagePayload)) {
    const dataString = messagePayload.data.toString();
    try {
      return JSON.parse(dataString);
    } catch {
      return undefined;
    }
  }
};

const isSensorMessage = allPass([isObject, isNotEmpty, hasIn('sensor')]);

export const useSensors = (topic: string) => {
  const {
    isSubscribed,
    connectionStatus,
    messagePayload,
    mqttSubscribe,
    mqttUnSubscribe,
  } = useAlcoBroker();

  const subscription = useMemo<MQTTSubscription>(() => ({ topic, qos: 1 }), [topic]);

  const [humi, setHumi] = useState<number | undefined>(undefined);
  const [temp, setTemp] = useState<number | undefined>(undefined);
  const [tvoc, setTVOC] = useState<number | undefined>(undefined);
  const [co2, setCo2] = useState<number | undefined>(undefined);
  const [alMin, setAlMin] = useState<number | undefined>(undefined);
  const [alMax, setAlMax] = useState<number | undefined>(undefined);
  const [alAvg, setAlAvg] = useState<number | undefined>(undefined);

  const data = useMemo<SensorsData>(() => ({ humi, temp, tvoc, co2, alMin, alMax, alAvg }), [
    humi,
    temp,
    tvoc,
    co2,
    alMin,
    alMax,
    alAvg,
  ]);

  useEffect(() => {
    if (connectionStatus === 'connected' && !isSubscribed(subscription.topic)) {
      mqttSubscribe(subscription);
    }
    return () => {
      mqttUnSubscribe(subscription);
    };
  }, [connectionStatus, isSubscribed, mqttSubscribe, mqttUnSubscribe, subscription]);

  useEffect(() => {
    if (messagePayload) {
      const parsedMessage = parseMessage(messagePayload);
      if (parsedMessage && isSensorMessage(parsedMessage)) {
        switch (parsedMessage.sensor) {
          case 'temperature':
            setTemp(parseFloat(parsedMessage.temperature));
            break;
          case 'humidity':
            setHumi(parseFloat(parsedMessage.humidity));
            break;
          case 'air_quality':
            setTVOC(parseInt(parsedMessage.tvoc));
            setCo2(parseInt(parsedMessage.c02));
            break;
          case 'audio_level':
            setAlMin(parseInt(parsedMessage.min));
            setAlMax(parseInt(parsedMessage.max));
            setAlAvg(parseInt(parsedMessage.avg));
            break;
        }
      }
    }
  }, [messagePayload]);

  return useMemo(
    () => ({
      isConnected: connectionStatus === 'connected',
      isSubscribed,
      data,
    }),
    [isSubscribed, connectionStatus, data],
  );
};
