import * as React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-elements';
import { keys } from 'ramda';

import { palette, spacing } from '../theme';
import { SensorsData, useSensors } from '../services/mqtt';
import { chunk } from '../utils/array';

const styles = StyleSheet.create({
  container: {
    backgroundColor: palette.black,
    borderRadius: spacing.tiny,
  },
  label: {
    color: palette.white,
    fontWeight: 'bold',
  },
  root: {
    backgroundColor: palette.black,
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    marginVertical: spacing.tiny,
    borderRadius: 2,
  },
  sensorBox: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  value: {
    color: palette.white,
    fontWeight: '400',
  },
});

export interface SensorsWidgetProps {
  mqttTopic: string;
  style?: ViewStyle;
}

/**
 * Describe your component here
 */
export function SensorsWidget({ mqttTopic, style }: SensorsWidgetProps) {
  const sensors = useSensors(mqttTopic);
  const getSensorData = (key: string) => {
    return sensors.data[key as keyof SensorsData] || '--';
  };

  return (
    <View style={styles.container}>
      {chunk(keys(sensors.data), 4).map((subset, chunkIndex) => (
        <View key={`sensor_${chunkIndex}`} style={[styles.root, style]}>
          {subset.map((key: string, index: number) => (
            <View key={`sensor_${index}`} style={styles.sensorBox}>
              <Text style={styles.label}>{`${key}: `}</Text>
              <Text style={styles.value}>{getSensorData(key)}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}
