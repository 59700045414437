import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

const styles = StyleSheet.create({
  aspect_ratio_16x9: {
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    position: 'relative',
  },
  aspect_ratio_inner_wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

export interface WebPlayerProps {
  streamId: string;
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle;
}

/**
 * Describe your component here
 */
export function WebPlayer({ streamId }: WebPlayerProps) {
  return (
    <View style={styles.aspect_ratio_16x9}>
      <View style={styles.aspect_ratio_inner_wrapper}>
        <iframe width={'100%'} height={'100%'} src={streamId} frameBorder="0" allowFullScreen />
      </View>
    </View>
  );
}
