import { IClientOptions, QoS } from 'mqtt';
import { useEffect, useMemo } from 'react';

import { useMQTT } from './use-mqtt';

export const useAlcoBroker = () => {
  const {
    isSubscribed,
    connectionStatus,
    messagePayload,
    mqttConnect,
    mqttDisconnect,
    mqttSubscribe,
    mqttUnSubscribe,
    mqttPublish,
  } = useMQTT();

  const config = useMemo(() => {
    const clientId = `vicaria_${Math.random().toString(16).substr(2, 8)}`;
    // const protocol = 'wss' as IClientOptions['protocol'];
    // const port = 8084;
    const protocol = 'ws' as IClientOptions['protocol'];
    const port = 8083;
    const host = 'emqx.alco.sudolabs.co';
    const path = 'mqtt';
    const url = `${protocol}://${host}:${port}/${path}`;
    const qos: QoS = 1;

    const options = {
      keepalive: 30,
      protocolId: 'MQTT',
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      will: {
        topic: 'WillMsg',
        payload: 'Connection Closed abnormally..!',
        qos,
        retain: false,
      },
      rejectUnauthorized: false, // self signed cert
      clientId,
    };

    return { url, options };
  }, []);

  useEffect(() => {
    mqttConnect(config.url, config.options);
    return () => {
      mqttDisconnect();
    };
  }, [config, mqttConnect, mqttDisconnect, mqttUnSubscribe]);

  return useMemo(
    () => ({
      isSubscribed,
      connectionStatus,
      messagePayload,
      mqttSubscribe,
      mqttUnSubscribe,
      mqttPublish,
    }),
    [isSubscribed, connectionStatus, messagePayload, mqttSubscribe, mqttUnSubscribe, mqttPublish],
  );
};
