import { Platform } from 'react-native';

import { palette } from './palette';
import { spacing } from './spacing';
import { typography } from './typography';

export { palette };
export { spacing };
export { typography };

export const theme = {
  colors: {
    /**
     * A helper for making something see-thru. Use sparingly as many layers of transparency
     * can cause older Android devices to slow down due to the excessive compositing required
     * by their under-powered GPUs.
     */
    transparent: palette.transparent,
    /**
     * The screen background.
     */
    background: palette.white,
    /**
     * The main tinting color.
     */
    primary: palette.green,
    /**
     * The secondary tinting color.
     */
    secondary: palette.blue,
    /**
     * A subtle color used for borders and lines.
     */
    border: palette.offWhite,
    /**
     * The default color of text in many components.
     */
    text: palette.darkGrey,
    /**
     * Secondary information.
     */
    dim: palette.lightGrey,
    /**
     * Error messages and icons.
     */
    error: palette.red,
  },
  Text: {
    style: {
      fontFamily: typography.primary,
    },
    h1Style: {
      fontSize: 25,
      fontWeight: 'bold',
      alignSelf: 'flex-start',
      left: 10,
      paddingTop: 5,
      width: 200,
    },
  },
  Button: {
    buttonStyle: {
      minHeight: spacing.huge,
      borderRadius: spacing.smaller,
    },
    titleStyle: {
      fontFamily: typography.primary,
      fontWeight: '500',
    },
  },
  Input: {
    inputContainerStyle: {
      borderColor: '#b6b7b9',
    },
    labelStyle: {
      fontFamily: typography.primary,
      fontWeight: 'normal',
      fontSize: 12,
      color: '#838485',
    },
    inputStyle: {
      fontFamily: typography.primary,
      fontSize: 16,
      color: '#000',
    },
    containerStyle: {
      height: 90,
    },
  },
  Card: {
    containerStyle: {
      borderColor: palette.white,
      ...Platform.select({
        android: {
          elevation: 1,
        },
        default: {
          shadowColor: 'rgba(0,0,0, .2)',
          shadowOffset: { height: 5, width: 0 },
          shadowOpacity: 0.34,
          shadowRadius: 6.27,
        },
      }),
      padding: spacing.smaller,
    },
  },
};
