import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'react-native-elements';
import { StyleSheet, View } from 'react-native';
import { allPass, hasIn, propSatisfies } from 'ramda';
import { isNotEmpty, isObject } from 'ramda-adjunct';

import { palette, spacing } from '../theme';
import { OfferWidget, RadioButton, WebPlayer } from '../components';
import { SensorsWidget } from '../components/sensors-widget';
import {
  MQTTMessagePayload,
  MQTTSubscription,
  useAlcoBroker,
} from '../services/mqtt';

const isValidStreamMessage = allPass([
  isObject,
  isNotEmpty,
  hasIn('sensor'),
  hasIn('action'),
  propSatisfies((sensor) => sensor === 'remote', 'sensor'),
  propSatisfies(
    (action) => action === 'stream1' || action === 'stream2',
    'action',
  ),
]);

const parsePayload = (payload: MQTTMessagePayload) => {
  if (payload && hasIn('data', payload)) {
    const dataString = payload.data.toString();
    try {
      return JSON.parse(dataString);
    } catch {
      return undefined;
    }
  }
};

const styles = StyleSheet.create({
  controlsBox: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: spacing.smaller,
  },
  switchLabel: {
    color: palette.darkGrey,
    fontSize: 16,
    fontWeight: '500',
    paddingHorizontal: spacing.smaller,
  },
});

const streamOptions = [
  { key: 'stream1', label: 'Stream 1' },
  { key: 'stream2', label: 'Stream 2' },
];

export function MissionScreen() {
  const {
    connectionStatus,
    isSubscribed,
    mqttSubscribe,
    mqttUnSubscribe,
    mqttPublish,
    messagePayload,
  } = useAlcoBroker();

  const [currentStream, setCurrentStream] = useState(streamOptions[0].key);
  const useH265 = useMemo(() => currentStream === streamOptions[1].key, [
    currentStream,
  ]);

  const subscription = useMemo<MQTTSubscription>(
    () => ({ topic: 'fromUno/salr-2', qos: 0 }),
    [],
  );

  const actionLeds = useCallback(
    (on: boolean) => {
      ['led1', 'led2'].forEach((sensor) =>
        mqttPublish({
          topic: 'toUno/sal-6-led',
          qos: 0,
          data: {
            sensor,
            action: on ? 'on' : 'off',
          },
        }),
      );
    },
    [mqttPublish],
  );

  const toggleStream = useCallback(
    (entry) => {
      setCurrentStream(entry.key);
      mqttPublish({
        topic: 'toUno/salr-2',
        qos: 0,
        data: {
          sensor: 'remote',
          action: useH265 ? streamOptions[0].key : streamOptions[1].key,
        },
      });
    },
    [mqttPublish, useH265],
  );

  useEffect(() => {
    // turn off all leds off on start
    actionLeds(false);
    // turn them off also on leave
    return actionLeds(false);
  }, [actionLeds]);

  useEffect(() => {
    if (connectionStatus === 'connected' && !isSubscribed(subscription.topic)) {
      mqttSubscribe(subscription);
    }
    return () => {
      mqttUnSubscribe(subscription);
    };
  }, [
    connectionStatus,
    isSubscribed,
    mqttSubscribe,
    mqttUnSubscribe,
    subscription,
  ]);

  useEffect(() => {
    if (messagePayload && messagePayload.topic === subscription.topic) {
      const message = parsePayload(messagePayload);
      if (isValidStreamMessage(message)) {
        setCurrentStream(message.action.toLowerCase());
      }
    }
  }, [messagePayload, subscription.topic]);

  return (
    <>
      <Card>
        <View style={{ display: useH265 ? 'none' : 'flex' }}>
          <WebPlayer streamId={'https://alco.sudolabs.co'} />
        </View>
        <View style={{ display: useH265 ? 'flex' : 'none' }}>
          <WebPlayer streamId={'https://alco.sudolabs.co/?h265=1'} />
        </View>
      </Card>
      <Card>
        <RadioButton
          entries={streamOptions}
          selectedKey={currentStream}
          containerStyle={styles.controlsBox}
          onChange={toggleStream}
        />
        <SensorsWidget mqttTopic={'fromUno/sal-8-sensors'} />
      </Card>
      <OfferWidget missionId={'#'} />
    </>
  );
}
